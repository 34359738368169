.nav {
  display: flex;
  flex-flow: row nowrap;
}

.list {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  gap: var(--spacing-4) var(--spacing-16);
}

.desktop{
  display: flex;
}

.tablet{
  display: none;
}

.mobile{
  display: none;
}

@media (max-width: 1180px) {
  .list {
    flex-flow: column wrap;
  }

  .desktop{
    display: none;
  }

  .tablet{
    margin-top: var(--spacing-34);
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .desktop{
    display: none;
  }

  .mobile{
    display: flex;
  }
}
