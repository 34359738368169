.layout{
  display: flex;
  flex-flow: column wrap;
  color: var(--white);
}

@media (max-width: 1024px) {
  .layout{
    gap: var(--spacing-24);
  }
}

@media (max-width: 768px){
  .layout{
    gap: var(--spacing-16);
  }
}