.list {
  display: flex;
  flex-flow: row nowrap;
  gap: var(--spacing-40);
  align-items: center;
}

.list .item {
  display: flex;
}

.link {
  position: relative;
  font: var(--font-p3-desktop-default);
  color: var(--beige);
  transition: color 0.2s;
  cursor: pointer;
}

.link::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 1rem;
  right: calc(var(--spacing-40) / -2);
  transform: translateX(50%);
  background-color: var(--beige);
  transition: width 0.5s;
  z-index: 1;
}

.list .item:last-child .link::after  {
  content: none;
}

.link:hover {
  color: var(--white);
}

.link:active {
  color: var(--grey);
}


@media (max-width: 768px) {
  .list{
    flex-flow: column wrap;
    gap: var(--spacing-16);
    align-items: flex-start;
  }
  .link::after {
    content: none
  }
}