.layout {
  display: grid;
  grid-template-columns: var(--grid-template-desktop);
}

.layout > *{
  display: grid;
  grid-template-columns: var(--grid-template-desktop);
  grid-column: full-start / full-end;
  scroll-margin-top: var(--header-height);
}

.layout > * > *{
  grid-column: main-start / main-end;
}

@media (max-width: 1024px) {
  .layout{
    grid-template-columns: var(--grid-template-mobile);
  }

  .layout > *{
    grid-template-columns: var(--grid-template-mobile);
  }
}