.section  {
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-16);
}

.title  {
  font: var(--font-p1-desktop-default);
  font-weight: 900;
}

.links  {
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-12);
}

.link  {
  font: var(--font-p2-desktop-default);
  color: var(--beige);
  transition: color 0.2s;
}

.link:hover  {
  color: var(--white);
}

.link:active  {
  color: var(--beige);
}

.picto{
  display: flex;
  flex-flow: column wrap;
  font-size: 0;
}

.row {
  flex-flow: row nowrap;
  gap: var(--spacing-24);
}


@media (max-width: 768px) {
  .section{
    gap: var(--spacing-8);
  }

  .title{
    font: var(--font-p1-desktop-default);
    font-weight: 900;
  }

  .link{
    font: var(--font-p2-mobile-default);
  }
}