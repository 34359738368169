
.languageSelector{
  display: flex;
  align-items: center;
  gap: var(--spacing-8);
  cursor: pointer;
  color: var(--black);
}

.languageSelectorIcon{
  --size-icon: 24px;
  width: var(--size-icon);
  height: var(--size-icon);
  color: var(--black);
}
