.menu {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: var(--mobile-header-height) var(--spacing-16) var(--spacing-40);
  background-color: var(--beige);
  
  /* closed */
  opacity: 0;
  visibility: hidden;
  transform: translateX(-100%);
  transition: transform .2s ease-in-out, opacity .2s ease-in-out, visibility .2s ease-in-out;
}

.menu.opened {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.burger {
  position: relative;
  z-index: 4;
  height: 32px;
  width: 32px;
  min-width: 32px;
  cursor: pointer;
}

.burger svg {
  width: 100%;
  height: 100%;
}

.line {
  fill: none;
  stroke: var(--black);
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.burger.cross .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.burger.cross .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.burger.cross .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
